﻿import { global } from "../Common/saop-common";
import { SaopRightSidebar,SaopRightSidebarView } from '../Components/saop-right-sidebar';
import { ObdobjefilterValues } from "../Components/saop-select-month-year";
import { SaopRegTodayEventsView } from "./saop-regTodayEvents-view"
import { SaopRegView } from "./saop-regUra-view";

export class SaopRegAttendanceView {
    public regTodayEventsView:SaopRegTodayEventsView;
    public regUraView:SaopRegView;
    public rightSidebar: SaopRightSidebar;
    public rightMonthSidebar: SaopRightSidebar;
    //

    constructor(libName: string = "") {
        this.regTodayEventsView = new SaopRegTodayEventsView("#regDogodekView0",libName+".regTodayEventsView");
        this.regUraView = new SaopRegView(libName+".regUraView");
        this.rightSidebar = new SaopRightSidebar();
        this.rightMonthSidebar = new SaopRightSidebar();
    }

    onInitView():void{
        global.ConsoleLogDegug("onInitView");
        this.regTodayEventsView.initView();
        this.regUraView.initView();
        //
        let obdobjefilterValues: ObdobjefilterValues = new ObdobjefilterValues();

        this.rightSidebar.bindCarouselDiv("#right-sidebar-ajax1", SaopRightSidebarView.AttendanceDay);
        this.rightSidebar.refreshRegUraDogodkiSummary("#regUraDogodkiSummary", obdobjefilterValues, "#saopLoadingSidebarr");
        //
        this.rightMonthSidebar.bindCarouselDiv("#right-sidebar-ajax2",SaopRightSidebarView.AttendanceMonth);
        this.rightMonthSidebar.refreshRegUraDogodkiSummary("#regUraMonthEventsSummary", obdobjefilterValues, "#saopLoadingRegUraMonthEventsSidebarr"); 
        //
        this.regTodayEventsView.afterRefreshData = this.afterRefreshMonthData.bind(this);
        this.regUraView.afterRefreshData = this.afterRefreshTodayData.bind(this);
        this.regUraView.onCloseView = this.onCloseRegUraDanView.bind(this);
    }

    afterRefreshTodayData(): void {
        global.ConsoleLogDegug("afterRefreshTodayData");
        // refresh "Evidenca prisotnosti - danasnji dan"
        this.regTodayEventsView.refreshListData(false);
        //refresh right sidebar
        this.afterRefreshRightSidebar();
    }

    afterRefreshMonthData(): void {
        global.ConsoleLogDegug("afterRefreshMonthData");
        this.regUraView.refreshRegUraBrowseView(false);
        // refresh "Evidenca prisotnosti - mesecna evidenca"
        this.afterRefreshRightSidebar();
    }    

    afterRefreshRightSidebar(): void {
        //refresh right sidebar
        let obdobjefilterValues: ObdobjefilterValues = new ObdobjefilterValues();
        this.rightSidebar.refreshRegUraDogodkiSummary("#regUraDogodkiSummary", obdobjefilterValues, "#saopLoadingSidebarr");
        let selectedMonthYear = this.regUraView._saopSelectMonthYear.getSelectedMonthYear();
        this.rightMonthSidebar.refreshRegUraDogodkiSummary("#regUraMonthEventsSummary", selectedMonthYear ,"#saopLoadingRegUraMonthEventsSidebarr");           
    }

    refreshAllData(): void {
        global.ConsoleLogDegug("refreshAllData");
        // refresh "Evidenca prisotnosti - danasnji dan"
        this.regTodayEventsView.refreshListData(false);
        //
        this.regUraView.refreshRegUraBrowseView(false);
        //refresh right sidebar
        this.afterRefreshRightSidebar();
    }    

    onCloseRegUraDanView():void{
        global.ConsoleLogDegug("SaopRegAttendanceView.onCloseRegUraDanView");    
        this.refreshAllData();
    }

    //
}


