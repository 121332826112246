﻿/// <reference path="../@types/alertify/index.d.ts" />
import { filterTableRows, global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopAbsenceConfirmationDetailsView } from "./saop-absenceConfirmation-details-view";
import { SaopPopover } from "../Components/saop-popover";

export class SaopAbsenceConfirmationProcessedView {
    private _viewId: string
    private _libName: string;
    private _detailsViewId: string
    private _saopServicesCore: SaopServicesCore;
    private _letoId: string;
    private _letoMobileFilterId: string;
    private _potrjeniCheckBoxId: string;
    private _potrjeniCheckBoxMobileFilterId: string;
    private _preklicaniCheckBoxId: string;
    private _preklicaniCheckBoxMobileFilterId: string;
    private _searchAllFieldsValueId: string;
    private _tableId: string;

    private saopAbsenceConfirmationDetailsView: SaopAbsenceConfirmationDetailsView;
    private _saopPopover: SaopPopover;

    constructor(viewId: string, libName: string = "") {
        this._viewId = viewId;
        this._libName = libName;
        this._detailsViewId = "#absenceConfirmationDetailsView0";
        this._saopServicesCore = new SaopServicesCore();

        // desktop filter html elements
        this._letoId = "#Leto";
        this._potrjeniCheckBoxId = "#cbpotrjeniCheckBox";
        this._preklicaniCheckBoxId = "#cbpreklicaniCheckBox";
        // mobile filter html elements
        this._letoMobileFilterId = "#letoFilter";
        this._potrjeniCheckBoxMobileFilterId ="#cbpotrjeniCheckBoxFilter"
        this._preklicaniCheckBoxMobileFilterId = "#cbpreklicaniCheckBoxFilter";

        this._searchAllFieldsValueId = "#OdsotnostiObdelaneSearchValue";
        this._tableId = "#absenceConfirmationProcessedTable";
        this.saopAbsenceConfirmationDetailsView = new SaopAbsenceConfirmationDetailsView(this._detailsViewId, this._libName + ".saopAbsenceConfirmationDetailsView");
        this.saopAbsenceConfirmationDetailsView.refreshDataExtended = this.refreshTableData.bind(this);
        this._saopPopover = new SaopPopover();
        this._saopPopover.afterPopoverShow = this.afterPopoverMobileFilterShow.bind(this);
    }

    initView(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.initView");

        this._saopPopover.bindPopoverEvents();

        this.initControls();
    }

    initControls(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.initControls");
        let _this = this;

        $(this._searchAllFieldsValueId).keyup(function () {
            let filterVal = String($(_this._searchAllFieldsValueId).val());
            filterTableRows(filterVal, _this._tableId);
        });
    }

    bindGridEvents() {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.bindGridEvents");
        let _this = this;
       
        $(".btnDetailsKEPlanDopustov").off("click");
        $(".btnDetailsKEPlanDopustov").on('click', function (row) {
            global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.btnDetailsKEPlanDopustov.click");
            let leto = row.target.getAttribute("data-leto");
            let sifraZaposleni = row.target.getAttribute("data-sifrazaposleni");
            let zaporednaStevilka = row.target.getAttribute("data-zaporednastevilka");
            let title = row.target.getAttribute("data-formtitle");

            _this.showRecordDetails({ leto: leto, sifraZaposleni: sifraZaposleni, zaporednaStevilka: zaporednaStevilka }, title);
        });
    }

    showRecordDetails(detailsData: any, title: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.showRecordDetails");

        this.saopAbsenceConfirmationDetailsView.showForm(detailsData, title);
    }

    refreshData(params: any = null): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.refreshData");
        let _this = this;

        this._saopServicesCore.afterDone = function (response) {
            $("#absenceConfirmationProcessed").html(response);
            _this.bindGridEvents();
            _this._saopPopover.bindPopoverEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/GetProcessedAbsences"
            , data: params
            , type: "GET"
            , spinnerDivId: "#absenceConfirmationProcessedLoading"
        });      
    }

    refreshTableData(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.refreshTableData");
        let _this = this;
        let filterValues = this.getFilterValues();

        this._saopServicesCore.afterDone = function (response) {
            $("#absenceConfirmationProcessedTableDiv").html(response);

            let filterVal = String($(_this._searchAllFieldsValueId).val());
            filterTableRows(filterVal, _this._tableId);

            _this.bindGridEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/GetProcessedAbsencesTable"
            , data: filterValues
            , type: "GET"
            , spinnerDivId: "#absenceConfirmationProcessedLoading"
        });     
    }

    // če je tip = "M", bereme vrednsoti iz HTML mobile elementov sicer iz desktop elementov
    getFilterValues(tip: string = ""): any {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.getFilterValues");

        let potrjeni = true;
        let potrjeniCheckBox = $(this._potrjeniCheckBoxId);
        if (tip == "M") {
            potrjeniCheckBox = $("div.popover.show").find(this._potrjeniCheckBoxMobileFilterId);
        }
        if (potrjeniCheckBox.length) {
            if (potrjeniCheckBox.is(":checked")) {
                potrjeni = true;
            } else {
                potrjeni = false;
            }
        }

        let preklicani = true;
        let preklicaniCheckBox = $(this._preklicaniCheckBoxId);
        if (tip == "M") {
            preklicaniCheckBox = $("div.popover.show").find(this._preklicaniCheckBoxMobileFilterId);
        }
        if (preklicaniCheckBox.length) {
            if (preklicaniCheckBox.is(":checked")) {
                preklicani = true;
            } else {
                preklicani = false;
            }
        }

        let leto = null;
        let letoInput = $(this._letoId)
        if (tip == "M") {
            letoInput = $("div.popover.show").find(this._letoMobileFilterId);
        }
        let letoStr = String(letoInput.val());
        if (letoStr.length == 4 || letoStr.length == 0) {
            if (letoStr.length == 4) {
                leto = +letoStr;
            }
        }

        return { leto: leto, potrjeni: potrjeni, preklicani: preklicani };
    }

    // kopiranje filter vrednosti iz html elementi za mobile v html elemente za desktop pregled
    copyFilterValues(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.copyFilterValues");

        // pridobimo vrednosti iz HTML mobile elementov
        let filterValues = this.getFilterValues("M");

        $(this._potrjeniCheckBoxId).prop('checked', filterValues.potrjeni);
        $(this._preklicaniCheckBoxId).prop('checked', filterValues.preklicani);
        $(this._letoId).val(filterValues.leto);
    }

    // če je tip = "M", je prisel klic iz mobile filter popover okna
    filtersChanged(tip: string = ""): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.filtersChanged");

        let filterValues = this.getFilterValues(tip);

        // zapisemo vrednosti tudi v drugi filter
        // ce je tip = "M" (mobile), se zapise vrednosti filtrov v html elementov za desktop pregled in obratno
        // potem se pri prikazu filtriranih podatkov upostevajo vrednosti iz deskotp html elementov
        if (tip == "M") {
            this.copyFilterValues();
        }

        // leto ne sme biti null
        if (filterValues.leto != null) {
            this.refreshTableData();
        }
    }

    afterPopoverMobileFilterShow(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.afterPopoverMobileFilterShow");

        // get filter values from desktop html elements
        let filterValues = this.getFilterValues();

        // write filter values to popover html elements when its showen

        var letoInput = $("div.popover.show").find(this._letoMobileFilterId);
        letoInput.val(filterValues.leto);

        var potrjeniCheckBox = $("div.popover.show").find(this._potrjeniCheckBoxMobileFilterId);
        potrjeniCheckBox.prop('checked', filterValues.potrjeni);

        var preklicaniCheckBox = $("div.popover.show").find(this._preklicaniCheckBoxMobileFilterId);
        preklicaniCheckBox.prop('checked', filterValues.preklicani);

        // prikazi filter okno
        var filterBody = $("div.popover.show").find("#popOverProcessedFilterBody");
        filterBody.show();
    }
}


