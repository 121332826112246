import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopViewForm, SaopWfAction } from "../Core/saop-view-form";
import { SaopUploadFiles } from "../Components/saop-upload-files";
import { SaopAttachmentList } from "../Components/saop-attachment-list";
import { OPNPotniNalogiGoggleMapsView } from "../OPN/OPNPotniNalogiGoggleMaps-view";
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';
import { SaopServicesCore } from "../Core/saop-services-core";
import { LIKVCakalnaVrstaComment } from "../LIKV/LIKVCakalnaVrsta-Comment";


export class LIKVCakalnaVrstaView extends SaopViewForm {
  private _formId:string;
  private _saopUploadFiles:SaopUploadFiles;
  private _saopAttachmentList: SaopAttachmentList;
  private _likvCakalnaVrstaComment : LIKVCakalnaVrstaComment;

  constructor(viewId:string,libName:string = ""){
    super(viewId,libName);
    this.title = this.translationService.translate("Potni nalog");
    this.addRecordEndpoint = "";
    this.editRecordEndpoint = "LikvConfirmations/Edit";
    this.deleteRecordEndpoint = ""; 
    this._saopUploadFiles = new SaopUploadFiles(viewId,libName);
    this._likvCakalnaVrstaComment = new LIKVCakalnaVrstaComment("#LIKVCakalnaVrstaView002",libName);      
    //
    this.afterViewShowSuccess = this.initView;
    this.afterSubmitSuccess = this.initView;
    this._saopAttachmentList = new SaopAttachmentList("#LikvCakalnaVrstaAttachments", libName + "._saopAttachmentList");
    this._saopAttachmentList.initComponent(
      {
          validationFingerprintController: "SPLDocument",
          validationFingerprintAction: "ValidateAttachmentFingerPrint",
          validationFingerprintBannerDivId: viewId + " #uploadFilesValidationsAjax",
          validationController: "SPLDocument",
          validationAction: "ValidateDownloadingDocumentAttachment",
          validationBannerDivId: viewId + " #uploadFilesValidationsAjax",
          downloadController: "SPLDocument",
          downloadAction: "DownloadDocumentAttachment",
          deletedAttachmentListId: viewId + " " + "#PriponkeBrisane"
      });

  }

  setDictionary(){
      let dictionary:string[] = ["Potrjevanje: prejeti račun"];
      this.setTerminologyList(dictionary);
  } 


  public initView():void{
    this.setDictionary();  
    this.LoadViewEvents();
  }

  public LoadViewEvents ():void{
    let _viewDiv = $(this.id);
    if (_viewDiv.length == 0) {return;}

    let _this = this;
    this._formId = "#mainLikvCakalnaVrstaEdit";
    //this._saopUploadFiles.initComponent({controller:"TravelOrder",action:"ValidateFiles",validateAjax:true,formId:this._formId,validationDivId:"#uploadFilesValidationsAjax"});
    this._saopUploadFiles.afterRefreshFilesList = this._saopUploadFiles.ajaxValidate;

    $('#btnOk').off('click');
    $('#btnOk').bind('click', 
      function() {
        let _result = _this._saopUploadFiles.ajaxValidate();
        return _result;        
      }          
    );    

    //$('.saop-attachment-element').bind('click', this.RefreshAttachment  );     
    $(".saop-attachment-element").on("click", function (e:any) {
      var data = e.currentTarget;
       let _id = data.getAttribute("data-docId");
      _this.refreshAttachment(_id);
    });     

    $('#LikvConfirmationsEditView').find("#btnDeny").off('click');
    $('#LikvConfirmationsEditView').find("#btnDeny").bind('click', 
      function() {
        _this._likvCakalnaVrstaComment.title = _this.translationService.translate("Zavrni dokument");        
        let _result = _this._likvCakalnaVrstaComment.showViewForm(SaopWfAction.wfaRecordDestails, null, null);
        return _result;        
      }          
    );

    $('#btnNext').off('click');
    $("#btnNext").on("click", function (e:any) {
        _this.MoveToNext();
    });  

    $('#btnPrevious').off('click');
    $("#btnPrevious").on("click", function (e:any) {
        _this.MoveToPrevious();
    }); 

  }

  private refreshHtmlAttachment(htmlElement:string){
    $("#likvConfirmationsAttachment").html(htmlElement);
  }

  public refreshAttachment (docID:string ):void {
    let _this = this;
    let _params = { documentID : docID };
    let _saopServicesCore =  new SaopServicesCore();

    _saopServicesCore.afterDone = function(response){
      _this.refreshHtmlAttachment(response);
    };
    _saopServicesCore.ExecuteAction({
        action:"LikvConfirmations/RefreshAttachment" 
        ,data: _params
        ,type:"GET"
    });    
  }

  public MoveToNext ():void{

  }

  public MoveToPrevious ():void{

  }

  //
}





