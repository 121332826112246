import { global, setModalFormZIndex } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";
import { LIKVCakalnaVrstaView } from "../LIKV/LIKVCakalnaVrsta-view";
import { SaopWfAction } from "../Core/saop-view-form";
import { LIKVCakalnaVrstaComment } from "../LIKV/LIKVCakalnaVrsta-Comment";
import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopPopover } from "../Components/saop-popover";
import { DataTables } from "../@types/data-tables/types";

export class LIKVCakalnaVrstaGrid {
    private libName:string;
    private _saopServicesCore: SaopServicesCore;
    private _mainFormDiv:JQuery<HTMLElement>;
    private _searchValue:JQuery<HTMLElement>;
    private _selectedRowNo:number;
    private _likvCakalnaVrstaComment : LIKVCakalnaVrstaComment;    
    //
    public webGrid1:SaopWebGrid;
    public _LIKVCakalnaVrstaView:LIKVCakalnaVrstaView;
    private _vrstaDokumenta: SaopMultiselect;  
    private _vrstaDokumentaMobile: SaopMultiselect;      
    private _vrstaDokumentaDivId: string;
    public _dashboard: boolean = false;   
    private _saopPopoverFilter: SaopPopover;       
    
    constructor(libName: string) {
        this.libName = libName;
        this._saopServicesCore = new SaopServicesCore();
        //
        this._LIKVCakalnaVrstaView = new LIKVCakalnaVrstaView("#LIKVCakalnaVrstaView001",this.libName+"._LIKVCakalnaVrstaView");
        this._LIKVCakalnaVrstaView.fullScreen = true;  
        this._LIKVCakalnaVrstaView.MoveToNext = this.MoveToNextExt.bind(this);
        this._LIKVCakalnaVrstaView.MoveToPrevious = this.MoveToPreviousExt.bind(this);        
        this._likvCakalnaVrstaComment = new LIKVCakalnaVrstaComment("#LIKVCakalnaVrstaView002",libName);
//        this._likvCakalnaVrstaComment.onDenyClick = this.onDenyClick.bind(this);
        this._vrstaDokumentaDivId = "#VrstaDokumentaDiv";  
        this._saopPopoverFilter = new SaopPopover();
        this._saopPopoverFilter.afterPopoverShow = this.afterPopoverMobileFilterShow.bind(this);              
      }  
      
      initView(){
        this._mainFormDiv = $("#LIKVCakalnaVrstaGridBrowseForm");
        this.initWebGrid1();
        this.initWebGrid1Listners();    
        this._searchValue = this._mainFormDiv.find("#SearchValue");   
        this.initFilterControls();  
        this.initVrstaDokumenta();    
        this._saopPopoverFilter.bindPopoverEvents();                  
      }    
      
      initWebGrid1(){
        let wgId = "#LIKVCakalnaVrstaGrid";
        let captionToolbar = $(wgId).attr("caption-toolbar");
        let toolbarItemsTemplateId = $(wgId).attr("toolbar-items-templateId");
        //
        this.webGrid1 = new SaopWebGrid(this.libName+".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        let toolbarButtons:SaopWebGridToolbarButton[] = [];
        let dataTable:SaopWebDataTable;
        //
        if (!this._dashboard) {
          gridToolbar = {id:"saopWebGridTB1",caption:captionToolbar,buttons:toolbarButtons,itemsTemplateId:toolbarItemsTemplateId};
       
          dataTable = { enabled:true, columnDefsSettings: [{targets:0,checkboxes:{selectRow:true}}] };
        }
        gridProperties = {
            gridId:wgId
            ,togglePanel:{enabled:false}
            ,toolbar:gridToolbar
            ,dataTable:dataTable
          };        
        //
        this.webGrid1.init(gridProperties);   
       
      } 
      
      public MoveToNextExt ():void{
        let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(this._selectedRowNo + 1); // here we extrapolate the row data of selected index
        if (_row.length > 0) {
          this._selectedRowNo = this._selectedRowNo + 1; // here we increase the index of the selected row
          //
          let _idVrste = _row.attr("data-idvrste");
          let _id = _row.attr("data-id"); 
          //
          this.editRecord(_idVrste,_id);
        }
      }

      public MoveToPreviousExt ():void{
        let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(this._selectedRowNo - 1); // here we extrapolate the row data of selected index
        if (_row.length > 0) {
          this._selectedRowNo = this._selectedRowNo - 1; // here we decrease the index of the selected row
          //
          let _idVrste = _row.attr("data-idvrste");
          let _id = _row.attr("data-id"); 
          //
          this.editRecord(_idVrste,_id);
        }
      }

      initWebGrid1Listners(){
        let _this = this;
        if (!this._dashboard) {      
          $("body").off("click", "#LIKVCakalnaVrstaGrid td:not(.td-action)");
          $("body").on("click", "#LIKVCakalnaVrstaGrid td:not(.td-action)", function () {
              let _i = $(this).closest('tr').index();
              _this._selectedRowNo = _i; // here we store the ID of the current row
              // let _rows = $('#LIKVCakalnaVrstaGrid > tbody > tr');
              // let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(_i);
              // let _idVrsteTest = _row[0].attributes[1].nodeValue;
              var data = $(this).closest("tr");
              let _idVrste = data.attr("data-IDVrste");
              let _id = data.attr("data-ID");
              _this.editRecord(_idVrste,_id);
          }); 
        } 
        
        $(".LIKVCakalnaVrstaGrid_deny").off("click");
        $(".LIKVCakalnaVrstaGrid_deny").on('click', function(row){
            global.ConsoleLogDegug("LIKVCakalnaVrstaGrid_deny.click");
            //$('#detailsOPNPotniNalogiForm').remove();
            let _i = $(this).closest('tr').index();
            if (_this._selectedRowNo == null)
              _this._selectedRowNo = _i; // here we store the ID of the current row                          
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
              _this._likvCakalnaVrstaComment.onDenyClick = _this.onDenyClick.bind(_this);   
              let _result = _this._likvCakalnaVrstaComment.editRecord({}); 
              //     let IDVrste = _btnEdit.getAttribute("data-IDVrste");
            //     let ID = _btnEdit.getAttribute("data-ID");
            //     _this.denyRecord(IDVrste,ID);
             }            
        });    
    
        $(".LIKVCakalnaVrstaGrid_confirm").off("click");
        $(".LIKVCakalnaVrstaGrid_confirm").on('click', function(row){
            global.ConsoleLogDegug("LIKVCakalnaVrstaGrid_confirm.click");
            //$('#detailsOPNPotniNalogiForm').remove();
            let _btnDelete = row.currentTarget;
            let btnEnabled = _btnDelete.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let IDVrste = _btnDelete.getAttribute("data-IDVrste");
                let ID = _btnDelete.getAttribute("data-ID");
                let IDAkcije = "-1";
                _this.confirmRecord(IDVrste,ID, IDAkcije);
            }            
        });        
    
        if (!this._dashboard) {
          let _btnConfirmGlobal = this._mainFormDiv.find("#btnConfirmGlobal");
          _btnConfirmGlobal.off("click");
          _btnConfirmGlobal.on('click', function(row){
              global.ConsoleLogDegug("btnConfirmGlobal.click");
              let rows_selected = _this.webGrid1.getSelectedRows();
              rows_selected.forEach(_el => {
                //console.log(_el);
                let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(Number(_el)); // here we extrapolate the row data of selected index
                if (_row.length > 0) {
                  //
                  let _idVrste = _row.attr("data-idvrste");
                  let _id = _row.attr("data-id"); 
                  let _idAkcije = _row.attr("data-idAkcije");                   
                  //
                  //console.log(_idVrste);
                  _this.confirmRecord(_idVrste, _id, _idAkcije);
                }

              });

          }); 

          let _btnDenyGlobal = this._mainFormDiv.find("#btnDenyGlobal");
          _btnDenyGlobal.off("click");
          _btnDenyGlobal.on('click', function(row){
              global.ConsoleLogDegug("btnDenyGlobal.click");
              _this._likvCakalnaVrstaComment.onDenyClick = _this.onDenyGlobalClick.bind(_this);             
              let _result = _this._likvCakalnaVrstaComment.editRecord({});             
          }); 
        }
        //
      }    

      confirmRecord(idVrste:string, id:string, idAkcije: string):void{
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid_confirm.click");
        let _this = this;
        //
        let _idVrste = idVrste;
        let _id = id;
        let _confirmationStatus = 0; 
        let _komentar = "";
        let _idAkcije = idAkcije;
        //
        let _data = { idVrste: _idVrste, id: _id, confirmationStatus: _confirmationStatus, komentar: _komentar, idAkcije: _idAkcije };
        //
        let _urlAction = "";
        _urlAction = "LikvConfirmations/SetConfirmationstatusGrid";        
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBanner").html(response.viewContent);
            }

            _this.refreshGridData(0, "");                     

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
        });     
      } 
           
      denyRecord(idVrste:string, id:string, komentar: string, idAkcije: string):void{
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid_deny.click");
        let _this = this;
        //
        let _idVrste = idVrste;
        let _id = id;
        let _confirmationStatus = 1;
        let _komentar = komentar;
        let _idAkcije = idAkcije;        
        //
        let _data = { idVrste: _idVrste, id: _id, confirmationStatus: _confirmationStatus, komentar: _komentar, idAkcije: _idAkcije };
        //
        let _urlAction = "";
        _urlAction = "LikvConfirmations/SetConfirmationstatusGrid";        
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBanner").html(response.viewContent);
            }

            _this.refreshGridData(0, "");             

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
        });      
      }        
      
      refreshData(htmlContent:string){
        this.webGrid1.replaceGridRows(htmlContent);
        this.initWebGrid1Listners();
      }

      refreshGridData(year: number, applicationCode: string = ""): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGrid.refreshRows:");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { year: year,  applicationCode: applicationCode };

        saopServicesCore.afterDone = function (response) {
          _this.refreshData(response);
        };
        saopServicesCore.ExecuteAction({
            action: "LikvConfirmations/Index"
            , data: params
            , type: "GET"
        });    
    }

    editRecord(_idVrste:string,_id:string){
      let _fullTitle = this._LIKVCakalnaVrstaView.translationService.translate("Potrjevanje: prejeti račun");
      this._LIKVCakalnaVrstaView.fullTitle = _fullTitle;
      this._LIKVCakalnaVrstaView.showViewForm(SaopWfAction.wfaEditRecord,{ queueId: _idVrste, threeId: _id, fullView: true });     
    }   
  
    initFilterControls(): void {
      let _this = this;
      this._searchValue.keyup(function () {
          let oTable = _this.webGrid1.dataTable;
          oTable.search($(this).val().toString()).draw() ;           
      });    
    }

    onDenyClick(){
      let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(this._selectedRowNo);
      if (_row.length > 0) {
        let _komentar =  $("#AddCommentView").find("#Komentar").val();  
        let _idVrste = _row.attr("data-idvrste");
        let _id = _row.attr("data-id"); 
        let _idAkcije = "-1";
        this.denyRecord(_idVrste, _id, _komentar.toString(), _idAkcije);
      }
    }

    onDenyGlobalClick(){
      let rows_selected = this.webGrid1.getSelectedRows();
      rows_selected.forEach(_el => {
        //console.log(_el);
        let _row = $('#LIKVCakalnaVrstaGrid > tbody > tr').eq(Number(_el)); // here we extrapolate the row data of selected index
        if (_row.length > 0) {
          //
          let _idVrste = _row.attr("data-idvrste");
          let _id = _row.attr("data-id"); 
          let _komentar = $("#AddCommentView").find("#Komentar").val();
          let _idAkcije = _row.attr("data-idAkcije");
          //
          //console.log(_idVrste);
          this.denyRecord(_idVrste, _id, _komentar.toString(), _idAkcije);
        }

      });  
    }

    initVrstaDokumenta(): void {
      global.ConsoleLogDegug("LIKVCakalnaVrstaView.initVrstaDokumenta");
  
      this._vrstaDokumenta = new SaopMultiselect();
      this._vrstaDokumenta.afterChangeCommonEvent = this.filterVrstaDokumenta.bind(this);
      // init za metodo "afterChangeCommonEvent", da se že v "_vrstaDokumenta.init" metodi sprozi filterVrstaDokumenta
      this._vrstaDokumenta.init("#VrstaDokumentaFilter", "Vrsta dokumenta");
      // prikazemo select div
      $(this._vrstaDokumentaDivId).show();
    }
  
    filterVrstaDokumenta(element: any) {
      global.ConsoleLogDegug("LIKVCakalnaVrstaView.filterVrstaDokumenta");
  
      // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
      let izbranaVrstaDokumenta = <string>(element.val().toString());
  
      this.refreshGridData(0, izbranaVrstaDokumenta);
    }  

    afterPopoverMobileFilterShow(element: HTMLElement): void {
      global.ConsoleLogDegug("LIKVCakalnaVrstaView.afterPopoverMobileFilterShow");

      let atrContent = element.getAttribute("data-popover-content");
      // preverimo če je popup likv filter
      if (atrContent === "#popOverLikvFilter") {

          this._vrstaDokumentaMobile = new SaopMultiselect();
          this._vrstaDokumentaMobile.init("div.popover.show #VrstaDokumentaFilterMobile", "Vrsta dokumenta");
          // metoda this.setSelectSkupinaMobileFilterValues se mora izvesti pred this._selectSkupinaMobileFilter.afterChangeCommonEvent
          // da se se ne izvede metoda this.selectSkupinaMobileFilterChanged, ker se v this.setSelectSkupinaMobileFilterValues
          // nastavijo vrednosti v klasu SaopMultiselect in posledicno se klice tudi afterChangeCommonEvent event
          this.setSelectVDMobileFilterValues();   
          this._vrstaDokumentaMobile.afterChangeCommonEvent = this.filterVrstaDokumentaMobileChanged.bind(this);

          // dodamo custom css za filter
          let filterPopoverDiv = $("div.popover.show");
          if (filterPopoverDiv != null) {
              let customCss = "calendar-filter";
              if (!filterPopoverDiv.hasClass(customCss)) {
                  filterPopoverDiv.addClass(customCss);
              }
          }

          // dodamo custom css za filter
          let filterPopoverBodyDiv = $("div.popover.show").find("div.popover-body");
          if (filterPopoverBodyDiv != null) {
              let customCss = "calendar-filter-body";
              if (!filterPopoverBodyDiv.hasClass(customCss)) {
                  filterPopoverBodyDiv.addClass(customCss);
              }
          }
      
          // prikazi filter okno
          let filterBody = $("div.popover.show").find("#popOverLikvFilterBody");
          filterBody.show();
      }
  }

  setSelectVDMobileFilterValues() {
    global.ConsoleLogDegug("LIKVCakalnaVrstaView.setSelectVDMobileFilterValues");
        // preberemo vrednosti VD iz desktop Category element
     let izbranaVrstaDokumenta = <string[]>(this._vrstaDokumenta.selectedCB.val());
     this._vrstaDokumentaMobile.setSelectValues(izbranaVrstaDokumenta);
}  

filterVrstaDokumentaMobileChanged(element: any) {
  global.ConsoleLogDegug("LIKVCakalnaVrstaView.filterVrstaDokumenta");

  // preberemo, VD in jih shranimo v desktop Category element
  let izbranaVrstaDokumenta = <string[]>(element.val());
  this._vrstaDokumenta.setSelectValues(izbranaVrstaDokumenta);

  this.refreshGridData(0, izbranaVrstaDokumenta.toString());  
}  

    //
}