﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopMultiselect } from "./saop-multiselect";
import { SaopPopover } from "../Components/saop-popover";

export enum SaopCalendarNavigation {
    refreshCell = -1
    , refreshTableData = 0
    , gotoPrevious = 1
    , gotoNext = 2
}

export interface SaopCalendarParams {
    controller:string,
    action:string,
    partialRender?:boolean
}

export class SaopCalendar {
    private _libName: string;
    private _params:SaopCalendarParams;
    private _saopServicesCore: SaopServicesCore;
    private _formFrame: JQuery<HTMLElement>;
    private _formData: JQuery<HTMLElement>;
    private _monthCaption: JQuery<HTMLElement>;
    private _btnPrevious: JQuery<HTMLElement>;
    private _btnNext: JQuery<HTMLElement>;
    public _multiSelectCategory: SaopMultiselect;
    private _saopPopover: SaopPopover;
    //
    get formData(): JQuery<HTMLElement> {
        return this._formData;
    }

    constructor(libName: string = "") {
        global.ConsoleLogDegug("SaopCalendar");
        this._libName = libName;
        this._saopServicesCore = new SaopServicesCore();
        this._saopPopover = new SaopPopover();
    }

    initView(params:SaopCalendarParams) {
        global.ConsoleLogDegug("SaopCalendar.initView");
        $("#saop-calendar-form").hide();
        let _this = this;
        this._params = params;
        this.bindDivs();
        this.initMultiSelect();
        $(window).resize(function () {
            _this.setTableHeigth();
        }).resize();

        this._saopPopover.bindPopoverEvents();

        $("#saop-calendar-form").show();
    }

    setTableHeigth(){
        var bodyheight = $(document).height();
        $("#saop-calendar-data").height(bodyheight-250);        
    }

    bindDivs() {
        global.ConsoleLogDegug("SaopCalendar.bindDivs");
        let _this = this;
        this._formFrame = $("#saop-calendar-form");
        this._formData = this._formFrame.find("#saop-calendar-data");
        this._monthCaption = $("#monthCaption");
        this._btnPrevious = this._formFrame.find("#btnPrevious");
        this._btnNext = this._formFrame.find("#btnNext");
        //
        this._btnPrevious.off("click");
        this._btnPrevious.click(function () {
            global.ConsoleLogDegug("SaopCalendar._btnPrevious");
            _this.refreshData(SaopCalendarNavigation.gotoPrevious);
        });
        this._btnNext.off("click");
        this._btnNext.click(function () {
            global.ConsoleLogDegug("SaopCalendar._btnNext");
            _this.refreshData(SaopCalendarNavigation.gotoNext);
        });
    }

    bindCaptions() {
        global.ConsoleLogDegug("SaopCalendar.bindCaptions");
        this._formData = this._formFrame.find("#saop-calendar-data");
        let dataPeriodCaption = this._formData.attr("data-periodCaption");
        let dataYear = this._formData.attr("data-year");        
        this._monthCaption.html(dataPeriodCaption+" "+dataYear);
    }

    refreshData(action: SaopCalendarNavigation,cellId?:string) {
        global.ConsoleLogDegug("SaopCalendar.refreshData");
        let dataYear = this._formData.attr("data-year");
        let dataMonth = this._formData.attr("data-month");
        let actionM = SaopCalendarNavigation.refreshTableData.toString();
        if (action == SaopCalendarNavigation.gotoPrevious) {
            actionM = SaopCalendarNavigation.gotoPrevious.toString();
        } else if (action == SaopCalendarNavigation.gotoNext) {
            actionM = SaopCalendarNavigation.gotoNext.toString();
        }
        if (action == SaopCalendarNavigation.refreshTableData || this._params.partialRender == null || this._params.partialRender == true){
            this.refreshTableDataAjaxCall(dataYear, dataMonth, actionM, cellId);
        } else {
            window.location.href = "../"+this._params.controller+"/"+this._params.action+"?periodYYYY=" + dataYear + "&periodMM="+dataMonth+"&periodAction="+actionM;
        }
    }

    refreshTableDataAjaxCall(periodYear: string, periodMonth: string, actionM: string, cellId?:string): void {
        global.ConsoleLogDegug("SaopCalendar.refreshTableDataAjaxCall");
        let _this = this;
        this._saopServicesCore.afterDone = function (response) {
            if(!isEmpty(cellId)) {
                _this.refreshCellData(response,cellId);
            } else {
                $("#saop-calendar-data").parent().html(response);
                _this.bindCaptions();
            }
            _this._saopPopover.bindPopoverEvents();
            _this.setTableHeigth();
            _this.filterData(_this._multiSelectCategory.selectedCB);
            _this.afterRefreshTableData(response); 
        };
        this._saopServicesCore.ExecuteAction({
            action: ""+this._params.controller+"/"+this._params.action+"?periodYYYY=" + periodYear + "&periodMM=" + periodMonth + "&periodAction=" + actionM + "&partial=true"
            , type: "GET"
        });
    }

    afterRefreshTableData(e: any) {
        global.ConsoleLogDegug("SaopCalendar.afterRefreshTableData");
    }

    refreshCellData(htmlResponse:string,elementId:string): void {
        global.ConsoleLogDegug("SaopCalendar.refreshCellData");
        let _document = $(htmlResponse);
        let _cell = _document.find(elementId);
        $(elementId).replaceWith(_cell);
    }

    filterData(element: any) {
        global.ConsoleLogDegug("SaopCalendar.filterData");
        this.externalFilterData(element);
    }

    // external metoda za filter, ki lahko nadomesti this.categoriesFilter metodo
    externalFilterData(element: any) {
        global.ConsoleLogDegug("SaopCalendar.externalFilterData-default");
        // klice default metodo
        this.categoriesFilter(element.val().toString());
    }

    initMultiSelect() {
        this._multiSelectCategory = new SaopMultiselect();
        this._multiSelectCategory.afterChangeCommonEvent = this.filterData.bind(this);
        this._multiSelectCategory.init("#Categories", "Zaposleni za prikaz");
    }

    categoriesFilter(filter: string = "") {
        // Declare variables
        var table, tr, _rowId, i, txtValue;
        table = document.getElementById("saop-calendar-table");
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            _rowId = null;
            let _trId = $(tr[i]).attr("id");
            if (!isUnDefined(_trId)) {
                _rowId = _trId.toString()
            } 
            if (_rowId) {
                txtValue = _rowId.toUpperCase();
                if (isEmpty(filter)) {
                    tr[i].style.display = "none";
                } else if (filter.toUpperCase().indexOf(txtValue) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    getHeaderControlGroupId(): string {
        return "saop-calendar-form-header-control-group";
    }
    //
}






