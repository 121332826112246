﻿import { SaopViewForm } from "../core/saop-view-form";
import { SaopServicesCore } from "../Core/saop-services-core";
import { global } from "../Common/saop-common";


export class SaopEmployeeLeaveCalendar extends SaopViewForm {
    private _saopServicesCore: SaopServicesCore;
    private _yearCaption: string = "yearCaption";
    private _btnPreviousId: string = "btnYearPrevious";
    private _btnNextId: string = "btnYearNext";
    public _sifraZaposleni:  string = "";

    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "AbsenceConfirmation/EmployeeAbsencesCalendarPartial";
        this.deleteRecordEndpoint = "";
        this.fullScreen = true;

        this._saopServicesCore = new SaopServicesCore();

        this.afterViewShowSuccess = this.initView;
        this.afterSubmitSuccess = this.initView;
    }

    initView(): void {
        this.LoadViewEvents();
    }

    public LoadViewEvents(): void {
        global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.bindGridEvents");
        let _this = this;

        $("#" + this._btnPreviousId).off("click");
        $("#" + this._btnPreviousId).click(function () {
            global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.btnPrevious.click");
            let leto = _this.getCurrentCalendarYear() - 1;
            _this.refreshCalendarAllMonthsData(leto);
        });

        $("#" + this._btnNextId).off("click");
        $("#" + this._btnNextId).click(function () {
            global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.btnNext.click");
            let leto = _this.getCurrentCalendarYear() + 1;
            _this.refreshCalendarAllMonthsData(leto);
        });
    }

    refreshCalendarAllMonthsData(leto: number): void {
        global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.refreshCalendarAllMonthsData");
        let _this = this;

        this._saopServicesCore.afterDone = function (response) {
            $("#regEmployeeAbsenceCalendar").html(response);
            _this.LoadViewEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/EmployeeAbsencesYearCalendarPartial"
            , type: "GET"
            , data: { leto: leto, sifraZaposleni: this._sifraZaposleni }
            , spinnerDivId: "#saopLoading"
        });
    }

    getCurrentCalendarYear(): number {
        global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.getCurrentCalendarYear");
        return $("#" + this._yearCaption).data("year");
    }
}


