﻿import { global} from "../Common/saop-common";
import { SaopWebGrid, SaopWebGridProperties, SaopWebGridToolbar, SaopWebGridToolbarButton, SaopWebDataTable } from "../Components/saop-grid";
import { SaopEmployeeLeaveCalendar } from "../AbsenceConfirmation/saop-employeeLeaveCalendar-view";
import { SaopTranslationService } from '../Core/saop-translate';
export class KeEployeeLeaveListGrid {
    private libName: string;
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;
    //
    public webGrid1: SaopWebGrid;

    private _saopRegKeZapDopustiView: SaopEmployeeLeaveCalendar;
    private _translationService: SaopTranslationService;

    constructor(libName: string) {
        this.libName = libName;

        this._saopRegKeZapDopustiView = new SaopEmployeeLeaveCalendar('#keEmployeeLeaveCalendar0', libName + ".saopRegKeZapDopustiView");


        this._translationService = new SaopTranslationService();
        this.setTerminologyList();
    }

    setTerminologyList() {
        let dictionary: string[] = ["Koledar"];
        this._translationService.setTranslationsList(dictionary);
    }   

    initView() {
        this._mainFormDiv = $("#KeEmployeeLeaveListBrowseForm");
        this.initWebGrid1();
        this._searchValue = this._mainFormDiv.find("#SearchValue");
        this.initFilterControls();
    }

    initWebGrid1(){
        let wgId = "#KeEmployeeLeaveList";
        let captionToolbar = $(wgId).attr("caption-toolbar");
        let toolbarItemsTemplateId = $(wgId).attr("toolbar-items-templateId");
        //
        this.webGrid1 = new SaopWebGrid(this.libName+".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        let toolbarButtons:SaopWebGridToolbarButton[] = [];
        let dataTable:SaopWebDataTable;
        //

        let _this = this;
        //
        gridToolbar = { caption: captionToolbar, buttons: toolbarButtons, itemsTemplateId: toolbarItemsTemplateId };

        dataTable = { enabled: true }

        gridProperties = {
            gridId: wgId
            , togglePanel: { enabled: false }
            , toolbar: gridToolbar
            , dataTable: dataTable
        };
        //
        this.webGrid1.init(gridProperties);

        $(document).on('click', '.categoryLink', function (e: any) {
            let _link = $("#" + e.target.id).attr("link");
            if (_link == null) {
                return;
            }

            const _data = JSON.parse(_link);
            _this.showEmployeeLeaveCalendar(_data.sifraZaposleni, _data.nazivZaposleni);
        });
    }

    initFilterControls(): void {
        let _this = this;
        this._searchValue.keyup(function () {
            let oTable = _this.webGrid1.dataTable;
            oTable.search($(this).val().toString()).draw() ;
        });
    }

    showEmployeeLeaveCalendar(sifraZaposleni: string, nazivZaposleni: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.showAddAbsenceForm1");

        let title = this._translationService.translate("Koledar") + ",  " + sifraZaposleni + " - " + nazivZaposleni;
        this._saopRegKeZapDopustiView.title = title;
        this._saopRegKeZapDopustiView._sifraZaposleni = sifraZaposleni;
        this._saopRegKeZapDopustiView.recordDetails({ sifraZaposleni: sifraZaposleni});
        this._saopRegKeZapDopustiView.SetToFullScreen();
    }
}






