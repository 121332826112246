﻿import { global, isEmpty, isUnDefined, filterTableRows, convertString2Date, formatDate } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopMultiselect, SelectOption } from "../Components/saop-multiselect";
import { SaopPopover } from "../Components/saop-popover";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebDataTable} from "../Components/saop-grid";
import { SaopExport } from "../Components/saop-export";

class ObdobjefilterValues {
    leto: number;
    mesec: number;
    mesecNaziv: string;
}

export class SaopRegAttendanceAllEmployeesAnalysisView {
    private _libName: string;
    private _refreshingAllEmployeesTable: boolean;
    private _tableDivId: string;
    private _tableId: string;
    private _searchAllFieldsValueId: string;
    private _obdobjeId: string;
    private _calendarIconId: string;
    private _obdobjeFilterLetoId: string;
    private _obdobjeFilterMesecId: string;
    private _obdobjeCurrentBtnId: string
    private _tableRowsId: string;
    private _selectDogodekHiddenId: string;
    private _selectSkupina: SaopMultiselect;
    private _selectZaposleni: SaopMultiselect;
    private _selectDogodek: SaopMultiselect;
    private _selectPrikazPodatki: SaopMultiselect;

    private _saopPopover: SaopPopover;
    //
    public webGrid1:SaopWebGrid;
    //

    constructor(libName: string = "") {
        this._libName = libName;
        this._refreshingAllEmployeesTable = false;
        this._tableDivId = "#attendanceAllEmployeesAnalysisTableDiv";
        this._tableId = "#attendanceAllEmployeesAnalysisTable";
        this._searchAllFieldsValueId = "#SearchValue";
        this._obdobjeId = "#Obdobje";
        this._calendarIconId = "#calendarIcon";
        this._obdobjeFilterLetoId = "#letoObdobje";
        this._obdobjeFilterMesecId = "#MesecString";
        this._obdobjeCurrentBtnId = "#currentMonthBtnFilter";
        this._tableRowsId = "#attendanceAllEmployeesAnalysisTableRows";
        this._selectDogodekHiddenId = "#EventsFilterHidden";

        this._saopPopover = new SaopPopover();
        this._saopPopover.afterPopoverShow = this.afterPopoverShow.bind(this); 
    }


    onInitView():void{
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.onInitView");
        this.refreshAllData();
    }

    initWebGrid1() {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.initWebGrid1");

        let wgId = this._tableId;
        let captionToolbar = $(wgId).attr("caption-toolbar");
        //let captionAddButton = $(wgId).attr("caption-addButton"); 
        //
        this.webGrid1 = new SaopWebGrid(this._libName+".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        //let toolbarButtons:SaopWebGridToolbarButton[] = [];
        let dataTable:SaopWebDataTable;
        //
        //toolbarButtons.push({id:"addTo",caption:captionAddButton,onClickEvent:this.libName+".addRecord();"});        
        //gridToolbar = {id:"saopWebGridTB1",caption:captionToolbar,buttons:toolbarButtons};
       
        dataTable = {enabled:true};
    
        gridProperties = {
            gridId:wgId
            //,toolbar:gridToolbar
            ,dataTable:dataTable
          };        
        //
        this.webGrid1.init(gridProperties);   

        // fix css za stolpce za tedne, da imajo lahko nazivi stolpcev dve vrstici
        let tedenCaptionheaderDivs = $(this._tableId + " thead tr th.saop-table-col-teden div.saop-table-header-caption");
        let indElement = 0
        tedenCaptionheaderDivs.each(function (index, divElement) {
            indElement = indElement + 1;
            $(divElement).removeClass("saop-table-text-truncate");
            $(divElement).addClass("saop-table-col-teden-caption");
            if (indElement == tedenCaptionheaderDivs.length) {
                $(divElement).addClass("saop-table-col-teden-caption-last");
            }
        });
      }

    refreshAllData(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.refreshAllData");

        this.refreshAllEmployeesData();
    }  

    refreshAllEmployeesData(params: any = null): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.refreshAllEmployeesData");
       
        let _this = this;
        let saopServicesCore = new SaopServicesCore();

        saopServicesCore.afterDone = function (response) {
            $("#attendanceAllEmployeesAnalysisForm").html(response);
            _this.initFilterControls();
            _this.bindGridEvents();
        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployeesAnalysis/GetAllEmployeesAnalysisForm"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesAnalysisLoading"
        });    
    }

    initFilterControls(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.initFilterControls");
        let _this = this;

        //
        this.setEventBindings();
        //

        this._selectSkupina = new SaopMultiselect();
        this._selectSkupina.afterChangeCommonEvent = _this.filterSelectSkupinaData.bind(_this);
        this._selectSkupina.init("#EnoteOrgStrukture", "Skupina za prikaz");

        this._selectZaposleni = new SaopMultiselect();
        this._selectZaposleni.afterChangeCommonEvent = this.filterTableRows.bind(this);
        this._selectZaposleni.init("#EmployeesFilter", "Zaposleni za prikaz");

        this._selectDogodek = new SaopMultiselect();
        this._selectDogodek.init("#EventsFilter", "Dogodki za prikaz");
        // event binding za init, da se v init metodi (se nastavi checkboxe na select all) ne izvede metoda filterEventColumns (ni potrebe)
        this._selectDogodek.afterChangeCommonEvent = _this.filterEventColumns.bind(_this);

        this._selectPrikazPodatki = new SaopMultiselect();
        this._selectPrikazPodatki.init("#PodatkiFilter", "Dodatno filtriranje");
        // odznacimo vse opcije
        let izbraniPodatki: string[] = [];
        this._selectPrikazPodatki.setSelectValues(izbraniPodatki);
        // event binding za init, da se v init metodi (se nastavi checkboxe na select all) ne izvede metoda filterEventColumns (ni potrebe)
        this._selectPrikazPodatki.afterChangeCommonEvent = _this.filterTableRows.bind(_this);

        $(this._searchAllFieldsValueId).keyup(function () {
            let oTable = _this.webGrid1.dataTable;
            oTable.search($(this).val().toString()).draw() ;           
            //$(_this.webGrid1.webGridId).dataTable().search($(this).val()).draw() ;
        });

        this._saopPopover.bindPopoverEvents();

        this.initObdobjeControl()
    }

    //filterAllFields(): void {
    //    global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterAllFields");

    //    let filterVal = String($(this._searchAllFieldsValueId).val());
    //    filterTableRows(filterVal, this._tableId);
    //}

    filterTableRows() {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterTableRows");

        // filter glede na izbrane sifre zaposlenih
        if (this._selectZaposleni) {
            this.filterSelectZaposleniData(this._selectZaposleni.selectedCB);
        }

        // filtiranje glede na dodatne kriterije
        if (this._selectPrikazPodatki) {
            this.filterPrikazPodatki(this._selectPrikazPodatki.selectedCB);
        }

        // reset vrednosti v searchallfields
        $(this._searchAllFieldsValueId).val("");
    }

    filterSelectZaposleniData(element: any) {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterSelectZaposleniData");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneSifreZaposlenih = <string>(element.val().toString());

        // filter vrstic
        this.filterZaposleniTableRows(izbraneSifreZaposlenih);
    }

    filterZaposleniTableRows(filterZaposleni: string) {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterZaposleniTableRows");

        // Declare variables
        var table, tr, _rowId, i, txtValue;
        table = document.getElementById("attendanceAllEmployeesAnalysisTable");
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            _rowId = null;
            let _trId = $(tr[i]).attr("id");
            if (!isUnDefined(_trId)) {
                _rowId = _trId.toString()
            }

            if (_rowId) {
                txtValue = _rowId.toUpperCase();
                if (isEmpty(filterZaposleni)) {
                    $(tr[i]).addClass("element-hidden");
                } else if (filterZaposleni.toUpperCase().indexOf(txtValue) > -1) {
                    $(tr[i]).removeClass("element-hidden");
                } else {
                    $(tr[i]).addClass("element-hidden");
                }
            }
        }
    }

    filterPrikazPodatki(element: any) {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterPrikazPodatki");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraniDodatniPogoji = <string>(element.val().toString());

        if (!isEmpty(izbraniDodatniPogoji)) {
            // filter vrstic
            this.filterDodatniPogojiTableRows(izbraniDodatniPogoji);
        }    
    }

    filterDodatniPogojiTableRows(filterDodatniPogoji: string) {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterDodatniPogojiTableRows");

        let prikaziTedenskaPrekoracitev = (filterDodatniPogoji.toUpperCase().indexOf("TED") > -1) ? true : false;
        let prikaziMesecnaPrekoracitev = (filterDodatniPogoji.toUpperCase().indexOf("MES") > -1) ? true : false;

        // samo tiste vrstice, ki so vidne
        let tableRows = $(this._tableId + ' tbody tr:not(.element-hidden)');
        tableRows.each(function (index, rowElement) {
            let addElementHiddenCss = true;
            if (prikaziTedenskaPrekoracitev) {
                if ($(rowElement).hasClass("tedenskiPodatki-nadure-error")) {
                    addElementHiddenCss = false;
                }
            }

            if (prikaziMesecnaPrekoracitev && addElementHiddenCss) {
                if ($(rowElement).hasClass("mesecniPodatki-nadure-error")) {
                    addElementHiddenCss = false;
                }
            }

            if (addElementHiddenCss) {
                $(rowElement).addClass("element-hidden");
            }
            else {
                $(rowElement).removeClass("element-hidden");
            }
        }); 
    }

    initObdobjeControl(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.initObdobjeControl");

        this.setObdobjeText(this._obdobjeId)
    }

    setObdobjeText(id: string): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.setObdobjeText");

        let obdobjeElement = $(this._obdobjeId);

        let mesecNaziv = obdobjeElement.attr("data-mesec-naziv");
        let leto = obdobjeElement.attr("data-leto");

        obdobjeElement.val(mesecNaziv + " " + leto);
    }

    getObdobjeDataAttributes(id: string): ObdobjefilterValues {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.getObdobjeDataAttributes");

        let obdobjeElement = $(this._obdobjeId);

        let dataAttributes = new ObdobjefilterValues();
        dataAttributes.leto = parseInt(obdobjeElement.attr("data-leto"));
        dataAttributes.mesec = parseInt(obdobjeElement.attr("data-mesec"));
        dataAttributes.mesecNaziv = obdobjeElement.attr("data-mesec-naziv");

        return dataAttributes;
    }
    
    setObdobjeDataAttributes(id: string, values: ObdobjefilterValues): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.setObdobjeDataAttributes");

        let obdobjeElement = $(id);

        if (values.leto != null && values.mesec != null) {
            obdobjeElement.attr("data-leto", values.leto);
            obdobjeElement.attr("data-mesec", values.mesec);
            obdobjeElement.attr("data-mesec-naziv", values.mesecNaziv);

            this.setObdobjeText(this._obdobjeId);
        } 
    }

    bindGridEvents(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.bindGridEvents");

        this.initWebGrid1();        
    }

    obdobjeClick(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.obdobjeClick");
        $(this._calendarIconId).trigger("click");
    }

    afterPopoverShow(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.afterPopoverShow");

        let obdobjeData = this.getObdobjeDataAttributes(this._obdobjeId);

        // osvezimo vrednosti filtrov iz data attributes
        this.setObdobjeFilterValues(obdobjeData);

        // prikazi okno
        var filterBody = $("div.popover.show").find("#popOverObdobjeBody");
        filterBody.show();
    }

    // klik na gumb trenutni v popover filtru za obdobje
    obdobjeCurrentMonth(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.obdobjeCurrentMonth");

        let currentMonthFilterValues = new ObdobjefilterValues();

        currentMonthFilterValues.leto = new Date().getFullYear();
        currentMonthFilterValues.mesec = new Date().getMonth() + 1;
        currentMonthFilterValues.mesecNaziv = this.getMonthName(currentMonthFilterValues.mesec);

        // zapisemo vrednost v filter leto
        this.setObdobjeFilterValues(currentMonthFilterValues);

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, currentMonthFilterValues);

        // osvezimo tabelo podatkov vseh zaposlenih
        this.refreshAllEmployeesTable(currentMonthFilterValues.leto, currentMonthFilterValues.mesec);
    }

    // na popover filtru za obdobje pomik za en mesec naprej, nazaj
    obdobjefiltersMonthStepChange(step: number): void {
        if (this._refreshingAllEmployeesTable == true) {
            // poteka postopek osvezevanja podatkov, novi zahtevek ni dovoljen 
            return;
        }

        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.obdobjefiltersMonthStepChange");

        // preberemo vrednosti leto in mesec
        let filterValues = this.getObdobjefilterValues();

        let newFilterValues = new ObdobjefilterValues();
        newFilterValues.mesec = filterValues.mesec + step;
        newFilterValues.leto = filterValues.leto;
        if (newFilterValues.mesec == 13) {
            // za december je januar
            newFilterValues.mesec = 1;
            newFilterValues.leto = newFilterValues.leto + 1;
        }
        else if (newFilterValues.mesec == 0) {
            // pred januarjem je december
            newFilterValues.mesec = 12;
            newFilterValues.leto = newFilterValues.leto - 1;
        }

        // poiscemo se naziv meseca
        newFilterValues.mesecNaziv = this.getMonthName(newFilterValues.mesec);

        // zapisemo novo vrednost v filter leto
        this.setObdobjeFilterValues(newFilterValues);

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, newFilterValues);

        // osvezimo tabelo podatkov vseh zaposlenih
        this.refreshAllEmployeesTable(newFilterValues.leto, newFilterValues.mesec);
    }

    getMonthName(month: number): string {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.getMonthName");

        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            return $(mesecElement).find(" option[value = '" + month + "']").text();
        }

        return "";
    }

    enableDisableObdobjeFilters(disable: boolean): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.enableDisableObdobjeFilters");

        // leto
        let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
        if (letoElement.length) {
            letoElement.prop("disabled", disable);
        }

        // mesec
        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            mesecElement.prop("disabled", disable);
        }  

        // gumb trenutni
        let _obdobjeCurrentBtnElement = $("div.popover.show").find(this._obdobjeCurrentBtnId);
        if (_obdobjeCurrentBtnElement.length) {
            _obdobjeCurrentBtnElement.prop("disabled", disable);
        } 
    }

    setObdobjeFilterValues(filterValues: ObdobjefilterValues): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.setObdobjeFilterValues");

        let oldfilterValues = this.getObdobjefilterValues();

        // leto
        if (oldfilterValues.leto != filterValues.leto) {
            let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
            if (letoElement.length) {
                letoElement.val(filterValues.leto);
            }
        }

        // mesec
        if (oldfilterValues.mesec != filterValues.mesec) {
            let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
            if (mesecElement.length) {
                mesecElement.val(filterValues.mesec.toString());
            }  
        } 
    }

    // vrednosti v filtru leto, mesec so se spremenile
    obdobjefiltersChanged(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.obdobjefiltersChanged");

        // preberemo vrednosti leto in mesec
        let filterValues = this.getObdobjefilterValues();

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, filterValues);

        this.refreshAllEmployeesTable(filterValues.leto, filterValues.mesec);
    }

    getObdobjefilterValues(): ObdobjefilterValues {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.getObdobjefilterValues");

        let filterValues = new ObdobjefilterValues();
        filterValues.leto = null;
        filterValues.mesec = null;
        filterValues.mesecNaziv = "";

        // dolocimo leto
        let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
        if (letoElement.length) {
            let letoStr = String(letoElement.val());
            if (letoStr.length == 4 || letoStr.length == 0) {
                if (letoStr.length == 4) {
                    filterValues.leto = +letoStr;
                }
            }
        }
        
        // dolocimo mesec
        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            let selMesecNaziv = $(mesecElement).find(":selected").text();
            let selMesec = $(mesecElement).find(":selected").val();

            if (!isNaN(+selMesec)) {
                filterValues.mesec = +selMesec
                filterValues.mesecNaziv = selMesecNaziv
            }
        }

        return filterValues;
    }

    refreshAllEmployeesTable(leto: number, mesec: number): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.refreshAllEmployeesTable");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { leto: leto, mesec: mesec };
        this._refreshingAllEmployeesTable = true;

        this.enableDisableObdobjeFilters(this._refreshingAllEmployeesTable);

        saopServicesCore.afterDone = function (response) {
            $(_this._tableDivId).html(response);

            _this.initWebGrid1();     

            _this._refreshingAllEmployeesTable = false;
            _this.enableDisableObdobjeFilters(_this._refreshingAllEmployeesTable);

            // izvedemo se filter vrstic tabele glede oznacenih zaposlenih
            let izbraniZaposleni = <string[]>(_this._selectZaposleni.selectedCB.val());
            _this._selectZaposleni.setSelectValues(izbraniZaposleni);

            // refresh options of selectDogodek html control
            _this.refreshSelectDogodekOptions();
        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployeesAnalysis/GetAllEmployeesAnalysisTable"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesAnalysisLoading"
        });    

    }

    afterRefreshTodayData(): void {
        global.ConsoleLogDegug("afterRefreshTodayData");
    }

    public setTableHeight(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.setTableHeight");

        let _height = $('#grid-toolbar-processed').height();
        let remaining_height = window.innerHeight - _height - 245;
        $('#attendanceAllEmployeesTable_wrapper').height(remaining_height);        
    }

    public setEventBindings(): void{
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.setEventBindings");

        let _this = this;

        _this.setTableHeight();
        window.onresize = function () {
            _this.setTableHeight();
        };

    }

    filterSelectSkupinaData(element: any) {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterSelectSkupinaData");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneSkupine = <string>(element.val().toString());
        let izbraneSifreZaposlenih: string[] = null;

        if (izbraneSkupine != null && izbraneSkupine.length > 0) {
            let izbraneSifreZaposlenihStr = this.getSelestedEmployeesIds(izbraneSkupine);
            izbraneSifreZaposlenih = izbraneSifreZaposlenihStr.split(",");
        }

        if (this._selectZaposleni != null) {
            this._selectZaposleni.setSelectValues(izbraneSifreZaposlenih);
        }
    }

    getSelestedEmployeesIds(izbraneSkupine: string): string {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.getSelestedEmployeesIds");

        let selectedEmployeesIds: string = "";
        let arrayIzbraneSkupine = izbraneSkupine.split(',');
        for (var ind = 0; ind < arrayIzbraneSkupine.length; ind++) {
            if (arrayIzbraneSkupine[ind] != null && arrayIzbraneSkupine[ind].length > 0) {
                // preberemo seznam zaposlenih iz hidden text html elementov
                let hiddetTextHtmlId = "#sifraEnoteOrgStrukture" + arrayIzbraneSkupine[ind].trim();
                let valu = $(hiddetTextHtmlId).val();
                valu = valu.toString().trim()
                selectedEmployeesIds = selectedEmployeesIds + valu;
                if (ind < (arrayIzbraneSkupine.length - 1)) {
                    selectedEmployeesIds = selectedEmployeesIds + ",";
                }
            }
        }
        return selectedEmployeesIds;
    }

    private refreshSelectDogodekOptions(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.refreshSelectDogodekOptions");

        let options: SelectOption[] = [];

        // preberemo options iz hiddent select kontrole
        $(this._selectDogodekHiddenId + " option").each(function () {
            options.push({ value: $(this).val().toString(), text: $(this).text() });
        });

        this._selectDogodek.refreshHtmlOptions(options, true);
    }

    private filterEventColumns(element: any): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterEventColumns");

        let _this = this;
        let izbraneSifreDogodkov = <string>(element.val().toString());
        let izbraneSifreDogodkovArray = izbraneSifreDogodkov.split(",");
        
        // event stolpci-headerji
        $(this._tableId + " thead tr th.saop-table-col-event").each(function (index, tableCellElement) {
            _this.showHideTableEventCell(tableCellElement, izbraneSifreDogodkovArray);
        });

        // event stolpci-podatki
        $(this._tableId + " tbody td.saop-table-col-event").each(function (index, tableCellElement) {
            _this.showHideTableEventCell(tableCellElement, izbraneSifreDogodkovArray);
        });
    }

    private showHideTableEventCell(element: HTMLElement, sifreDogodkovArray: string[]): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.filterEventColumns");

        let showTableCellElement = false;
        let $tableCellElement = $(element);

        for (var i = 0; i < sifreDogodkovArray.length; i++) {
            if ($tableCellElement.hasClass("saop-table-col-event-" + sifreDogodkovArray[i].toLowerCase())) {
                showTableCellElement = true;
            }
        }

        if (showTableCellElement) {
            $tableCellElement.removeClass("element-hidden");
        }
        else {
            $tableCellElement.addClass("element-hidden");
        }
    }

    public exportCsv() {
        let saopExport = new SaopExport();

        saopExport.exportTableData2Csv(this._tableId);
    }
}


